import React, {Component, useCallback, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import {
FirebaseAuthProvider,
FirebaseAuthConsumer,
IfFirebaseAuthed,
IfFirebaseAuthedAnd
} from "@react-firebase/auth";
  
import firebase from "firebase/app";
import "firebase/auth";
import { theFirebaseConfig } from "./fb.config";

//import { Formik } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import { FormControl } from '@material-ui/core';
import cbRequest from "./CBRequest.js";

const $ = require("jquery");

//Use the associated firebase config
let firebaseConfig = theFirebaseConfig.stagingFirebaseConfig;
if(window._env_.ENV == "production") {
  firebaseConfig = theFirebaseConfig.prodFirebaseConfig;
} 
// let firebaseConfig = theFirebaseConfig.prodFirebaseConfig;

const CBLoginForm = (callback) => {
        
    const formik = useFormik({
        initialValues: {
          email: '',
        },
        validationSchema: Yup.object({
        //   firstName: Yup.string()
        //     .max(15, 'Must be 15 characters or less')
        //     .required('Required'),
          email: Yup.string().email('Please enter a valid email address').required('Required'),
        }),
        onSubmit: values => {
            const parts = window.location.pathname.split("/");
           
            // Pass the email and top level collection
            cbRequest.verifyUserEmail(values.email, parts[1], (isValid) => {
                //Logout and display alert
                if (!isValid) {
                    callback.showUnauthorized();
                    return;
                }
                //console.log("Redirect")
                var actionCodeSettings = {
                    // URL you want to redirect back to. The domain (www.example.com) for this
                    // URL must be in the authorized domains list in the Firebase Console.
                    //url: 'http://localhost:8443/mothers?',
                    url: window.location.origin + '/' + parts[1] + "/mothers/all",
                    // url : "https://staging.mommi.us/" + parts[1] + "/mothers/all",
                    // This must be true.
                    handleCodeInApp: true,
                };
                
                //Send email to user
                firebase.auth().sendSignInLinkToEmail(values.email, actionCodeSettings)
                    .then(() => {
                        // The link was successfully sent. Inform the user.
                        // Save the email locally so you don't need to ask the user for it again
                        // if they open the link on the same device.
                        window.localStorage.setItem('emailForSignIn', values.email);
                        callback.updateEmailState(true, values.email)
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log("Firebase Error: ", errorCode, errorMessage)
                }); 
            })

               
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth style= {{ width: '80%' }}>
                <TextField id="email" name="email" label="Email" variant="outlined" type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                {formik.errors.email ? <Typography variant="caption" display="block" style={{ textAlign : "left"}} gutterBottom>
                    {formik.errors.email}
                </Typography> : null}
            </FormControl>
            <FormControl fullWidth />
            <Button variant="contained" color="secondary" size="large" type="submit">
                Sign In with Email
            </Button>
            <Button variant="contained" color="secondary" size="large" style={{ marginLeft : '5px'}} onClick={() => {
                const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithPopup(googleAuthProvider)
                               .then( (result) => {
                                   console.log("GOOGLE LOGIN RES: ", result)

                                    if (result.user.email != null) {
                                        //Must ensure this user is expected 
                                        callback.verifyEmail(result.user.email, (isValid) => {
                                            //Logout and display alert
                                            if (!isValid) {
                                                callback.showUnauthorized();
                                                return;
                                            }
                                        })
                                    }
                               })
            }}>
                Sign In with Google
            </Button>
        </form>
    );
};


class FirebaseSetup extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            emailSent : false, 
            email: "",
            isSignedIn : false,
            user : null,
            handleSignInStatus : props.signOutHandler,
            checkIfSigningOut : props.checkIfSigningOut
        };

        this.checkIfCompletedSignIn = this.checkIfCompletedSignIn.bind(this);
        this.updateEmailState = this.updateEmailState.bind(this);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.showUnauthorized = this.showUnauthorized.bind(this);

        firebase.auth().onAuthStateChanged((user) => {
            console.log("onAuthStatechanged ",user )
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              //var uid = user.uid;
              //this.setState({...this.state, user : user })
              cbRequest.setUser(user);
              this.state.handleSignInStatus(true);

            } else {
              // User is signed out
              // ...
              this.state.handleSignInStatus(false);
            }
        });
    }

    componentDidMount() {

        if (this.state.checkIfSigningOut()) { return; }
        this.checkIfCompletedSignIn();
    }
    checkIfCompletedSignIn(){

        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    console.log("User completed email sign-in!")
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
            

        }
    }

    updateEmailState(emailSent, email){
        console.log("udpateEmailState")
        this.setState({ emailSent : emailSent, email : email})
    }

    verifyEmail(email, cb) {
        const parts = window.location.pathname.split("/");

        cbRequest.verifyUserEmail(email, parts[1], (isValid) => {
            cb(isValid);
        })
    }

    showUnauthorized() {
        document.body.innerHTML = "";
        firebase.auth().signOut();
        window.alert("This email address is not an authorized email.  Logging out.")
    }
    render() {
        //console.log(firebaseConfig)
        return (

            <FirebaseAuthProvider {...firebaseConfig} firebase={firebase} className="App">
                <div>
                    <h1>Mommi Login</h1>
                    <p> Provide an email address or use Google to sign-in</p>
                    
                    <CBLoginForm updateEmailState={this.updateEmailState} verifyEmail={this.verifyEmail} showUnauthorized={this.showUnauthorized}/>
                    {/* <CBLoginForm  />                        */}
                    <FormControl fullWidth>
                        { this.state.emailSent ? <p> Email has been sent to {this.state.email}. Please click link in email to complete sign-in</p> : null } 
                    </FormControl>
                    <div>
                        <IfFirebaseAuthed>
                        {() => {
                            return <div>You are authenticated</div>;
                        }}
                        </IfFirebaseAuthed>
                        <IfFirebaseAuthedAnd
                        filter={({ providerId }) => providerId !== "anonymous"}
                        >
                        {({ providerId }) => {
                            return <div>You are authenticated with {providerId}</div>;
                        }}
                        </IfFirebaseAuthedAnd>
                    </div>
                    <p>v2.19</p>
                </div>
          </FirebaseAuthProvider>
        )
    }
}

export default FirebaseSetup;